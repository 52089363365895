var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"modal-close-toggle\">\n          <i class=\"ygicon-popup-close popup-close\" data-dismiss=\"modal\"></i>\n        </div>\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"question-response-column\">\n            <div class=\"question-response-item\">\n              <label class=\"response-label\" for=\"r-"
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"input_id") : depths[1]), depth0))
    + "-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"code") : depth0), depth0))
    + "\">\n                <input id=\"r-"
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"input_id") : depths[1]), depth0))
    + "-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"code") : depth0), depth0))
    + "\" class=\"response-input\" name=\""
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"input_id") : depths[1]), depth0))
    + "\"\n                "
    + ((stack1 = __default(require("../js/helpers/ifans.js")).call(alias3,(depths[1] != null ? lookupProperty(depths[1],"answer") : depths[1]),(depths[1] != null ? lookupProperty(depths[1],"input_id") : depths[1]),(depth0 != null ? lookupProperty(depth0,"code") : depth0),{"name":"ifans","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":16},"end":{"line":27,"column":26}}})) != null ? stack1 : "")
    + " type=\"radio\" value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"code") : depth0), depth0))
    + "\">\n                <span class=\"label-inner\">\n                  <span class=\"label-text\"\n                    >"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"text") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.program(8, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":30,"column":21},"end":{"line":30,"column":64}}})) != null ? stack1 : "")
    + "</span\n                  >\n                </span>\n              </label>\n            </div>\n          </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return " checked=\"checked\"\n                ";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0)) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "&nbsp;";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div\n  id=\"r-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"input_idx") : depth0), depth0))
    + "-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"code") : depth0), depth0))
    + "\"\n  class=\"trailing-popup modal fade\"\n  role=\"dialog\"\n  data-required=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"required") : depth0), depth0))
    + "\"\n  data-required-text=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"required_text") : depth0), depth0))
    + "\"\n>\n  <div class=\"modal-dialog modal-sm\">\n    <div class=\"modal-content\">\n      <div class=\"modal-header\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"show_close_button") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":8},"end":{"line":15,"column":15}}})) != null ? stack1 : "")
    + "      </div>\n      <div class=\"modal-body\">\n        <div class=\"question-text\">"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0)) != null ? stack1 : "")
    + "</div>\n        <div class=\"question-response-list\">\n"
    + ((stack1 = container.invokePartial(require("./error.hbs"),depth0,{"name":"error","data":data,"indent":"          ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"response_options") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":10},"end":{"line":36,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n      </div>\n      <div class=\"modal-footer\"></div>\n    </div>\n  </div>\n</div>\n";
},"usePartial":true,"useData":true,"useDepths":true});