var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"therm_cont clearfix\">\n    <legend class=\"question-text\" id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"element_id") : depth0), depth0))
    + "-label\">"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"qtext") : depth0), depth0)) != null ? stack1 : "")
    + "</legend>\n"
    + ((stack1 = __default(require("../js/helpers/ifanswerexists.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"resp_key") : depth0),{"name":"ifanswerexists","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":1},"end":{"line":15,"column":23}}})) != null ? stack1 : "")
    + "    <div class=\"therm_instructions\">"
    + ((stack1 = alias1((depths[1] != null ? lookupProperty(depths[1],"instructions") : depths[1]), depth0)) != null ? stack1 : "")
    + "</div>\n\n    <div class=\"temperature-indicator-mobile\">\n      <label for=\"r-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"resp_key") : depth0), depth0))
    + "-mobile\"><strong>Temperature</strong></label>\n      <input\n        class=\"slider-input-mobile\"\n        min=\""
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"therm_min") : depths[1]), depth0))
    + "\"\n        max=\""
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"therm_max") : depths[1]), depth0))
    + "\"\n        type=\"number\"\n        id=\"r-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"resp_key") : depth0), depth0))
    + "-mobile\"\n        name=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"resp_key") : depth0), depth0))
    + "-mobile\"\n        value=\""
    + alias2(__default(require("../js/helpers/answer.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"resp_key") : depth0),{"name":"answer","hash":{},"data":data,"loc":{"start":{"line":27,"column":15},"end":{"line":27,"column":34}}}))
    + "\"\n        inputmode=\"numeric\"\n      />\n    </div>\n    <div class=\"therm_middle\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"img") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":6},"end":{"line":34,"column":13}}})) != null ? stack1 : "")
    + "\n      <div class=\"therm-container\">\n        <div class=\"levels\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"labels") : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":10},"end":{"line":43,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n        <div class=\"thermometer\" id=\"option-1\">\n          <div class=\"results\">\n            <div class=\"on\">\n              <div class=\"therm-drag ui-slider-handle\">\n                <input\n                  class=\"slider-input\"\n                  min=\""
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"therm_min") : depths[1]), depth0))
    + "\"\n                  max=\""
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"therm_max") : depths[1]), depth0))
    + "\"\n                  type=\"number\"\n                  id=\"r-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"resp_key") : depth0), depth0))
    + "\"\n                  name=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"resp_key") : depth0), depth0))
    + "\"\n                  value=\""
    + alias2(__default(require("../js/helpers/answer.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"resp_key") : depth0),{"name":"answer","hash":{},"data":data,"loc":{"start":{"line":56,"column":25},"end":{"line":56,"column":44}}}))
    + "\"\n                  inputmode=\"numeric\"\n                />\n                <div class=\"slider-indicator\">\n                  <div class=\"slider-indicator-line\"></div>\n                  <div class=\"slider-indicator-dot\"></div>\n                </div>\n              </div>\n            </div>\n            <div class=\"off\"></div>\n          </div>\n          <div class=\"top-rounded\"></div>\n        </div>\n      </div>\n      "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depths[1] != null ? lookupProperty(depths[1],"dk") : depths[1]),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":70,"column":6},"end":{"line":72,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n  </div>\n";
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div role=\"status\" class=\"sr-only\" tabindex=\"0\">\n      Previous answer is \n"
    + ((stack1 = __default(require("../js/helpers/ifans.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"answer") : depth0),(depth0 != null ? lookupProperty(depth0,"dk_resp") : depth0),"on",{"name":"ifans","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.program(5, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":9,"column":6},"end":{"line":13,"column":16}}})) != null ? stack1 : "")
    + "    </div>\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + container.escapeExpression(container.lambda((depths[3] != null ? lookupProperty(depths[3],"dk_text") : depths[3]), depth0))
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + container.escapeExpression(__default(require("../js/helpers/answer.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"resp_key") : depth0),{"name":"answer","hash":{},"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":12,"column":27}}}))
    + "\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"therm_img\"><img src=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"img") : depth0), depth0))
    + "\" alt=\"\" border=\"0\" /></div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div id=\"val-"
    + alias2(alias1((data && lookupProperty(data,"key")), depth0))
    + "\" class=\"level\">\n            <div class=\"level-label\">"
    + alias2(alias1(depth0, depth0))
    + "</div>\n            <div class=\"level-degrees\">"
    + alias2(alias1((data && lookupProperty(data,"key")), depth0))
    + "°</div>\n          </div>\n";
},"11":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + ((stack1 = __default(require("../js/helpers/partial.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"dk-partial",{"name":"partial","hash":{"index":(data && lookupProperty(data,"index")),"dk_id":(depth0 != null ? lookupProperty(depth0,"dk_resp") : depth0),"dk_value":997,"response_id":(depth0 != null ? lookupProperty(depth0,"response_id") : depth0),"dk_text":(depths[1] != null ? lookupProperty(depths[1],"dk_text") : depths[1])},"data":data,"loc":{"start":{"line":70,"column":20},"end":{"line":71,"column":73}}})) != null ? stack1 : "")
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<fieldset class=\"question question-thermometer\">\n"
    + ((stack1 = container.invokePartial(require("./error.hbs"),depth0,{"name":"error","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"categories") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":2},"end":{"line":75,"column":11}}})) != null ? stack1 : "")
    + "</fieldset>";
},"usePartial":true,"useData":true,"useDepths":true});