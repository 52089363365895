var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\"dropdown-inline\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"subtext") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.program(4, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":4,"column":2},"end":{"line":23,"column":9}}})) != null ? stack1 : "")
    + "</span>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"dropdown-subtext-cont\">\n"
    + ((stack1 = container.invokePartial(require("./error.hbs"),depth0,{"name":"error","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    <div class=\"dropdown-subtext-message\" id=\""
    + container.escapeExpression(alias1((depth0 != null ? lookupProperty(depth0,"element_id") : depth0), depth0))
    + "-label\" tabindex=\"-1\">\n      "
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"subtext") : depth0), depth0)) != null ? stack1 : "")
    + "\n    </div>\n  </div>\n";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <select name=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"input_id") : depth0), depth0))
    + "\" aria-labelledby=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"element_id") : depth0), depth0))
    + "-label\">\n    <option value=\"\" selected disabled>"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"default_text") : depth0), depth0)) != null ? stack1 : "")
    + "</option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"response_options") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":4},"end":{"line":21,"column":13}}})) != null ? stack1 : "")
    + "  </select>\n";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <option\n      value=\""
    + container.escapeExpression(alias1((depth0 != null ? lookupProperty(depth0,"code") : depth0), depth0))
    + "\"\n"
    + ((stack1 = __default(require("../js/helpers/ifans.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"answer") : depths[1]),(depths[1] != null ? lookupProperty(depths[1],"input_id") : depths[1]),(depth0 != null ? lookupProperty(depth0,"code") : depth0),{"name":"ifans","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":6},"end":{"line":19,"column":16}}})) != null ? stack1 : "")
    + "      >"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0)) != null ? stack1 : "")
    + "</option>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "      selected=\"selected\"\n";
},"8":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<fieldset class=\"question question-dropdown\">\n  <legend class=\"question-text\" id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"element_id") : depth0), depth0))
    + "-label\">\n    "
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0)) != null ? stack1 : "")
    + "\n  </legend>\n"
    + ((stack1 = __default(require("../js/helpers/ifanswerexists.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"input_id") : depth0),{"name":"ifanswerexists","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":2},"end":{"line":39,"column":21}}})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./error.hbs"),depth0,{"name":"error","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  <div class=\"question-open-ended-input\">\n    <select name=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"input_id") : depth0), depth0))
    + "\">\n      <option value=\"\" selected disabled>"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"default_text") : depth0), depth0)) != null ? stack1 : "")
    + "</option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"response_options") : depth0),{"name":"each","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":6},"end":{"line":51,"column":15}}})) != null ? stack1 : "")
    + "    </select>\n    <div id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"element_id") : depth0), depth0))
    + "-options\" class=\"sr-only\" role=\"status\">\n      Dropdown options:\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"response_options") : depth0),{"name":"each","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":6},"end":{"line":57,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n  </div>\n</fieldset>\n";
},"9":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div role=\"status\" class=\"sr-only\" tabindex=\"0\" id=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"input_id") : depth0), depth0))
    + "_note\">\n    Previous answer is \n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"response_options") : depth0),{"name":"each","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":4},"end":{"line":37,"column":13}}})) != null ? stack1 : "")
    + "  </div>\n";
},"10":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../js/helpers/ifans.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"answer") : depths[1]),(depths[1] != null ? lookupProperty(depths[1],"input_id") : depths[1]),(depth0 != null ? lookupProperty(depth0,"code") : depth0),{"name":"ifans","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":6},"end":{"line":36,"column":16}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0)) != null ? stack1 : "")
    + "\n";
},"13":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <option\n        value=\""
    + container.escapeExpression(alias1((depth0 != null ? lookupProperty(depth0,"code") : depth0), depth0))
    + "\"\n"
    + ((stack1 = __default(require("../js/helpers/ifans.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"answer") : depths[1]),(depths[1] != null ? lookupProperty(depths[1],"input_id") : depths[1]),(depth0 != null ? lookupProperty(depth0,"code") : depth0),{"name":"ifans","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":8},"end":{"line":49,"column":18}}})) != null ? stack1 : "")
    + "        >"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0)) != null ? stack1 : "")
    + "</option>\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "        selected=\"selected\"\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0)) != null ? stack1 : "")
    + ",\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"responses") : depth0), depth0))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"is_subwidget") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(8, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":61,"column":7}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true,"useDepths":true});