var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div role=\"alert\" tabindex=\"0\" class=\"validation-result alert alert-error\">\n    "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"error_message") || (depth0 != null ? lookupProperty(depth0,"error_message") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"error_message","hash":{},"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":4,"column":21}}}) : helper)))
    + "\n  </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"skip-wrap\">\n      <input\n        type=\"checkbox\"\n        id=\"r-"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"skip_id") || (depth0 != null ? lookupProperty(depth0,"skip_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"skip_id","hash":{},"data":data,"loc":{"start":{"line":31,"column":14},"end":{"line":31,"column":27}}}) : helper))) != null ? stack1 : "")
    + "\"\n        name=\""
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"skip_id") || (depth0 != null ? lookupProperty(depth0,"skip_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"skip_id","hash":{},"data":data,"loc":{"start":{"line":32,"column":14},"end":{"line":32,"column":27}}}) : helper))) != null ? stack1 : "")
    + "\"\n        class=\"skip\"\n      />\n      <span>\n        <label for=\"r-"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"skip_id") || (depth0 != null ? lookupProperty(depth0,"skip_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"skip_id","hash":{},"data":data,"loc":{"start":{"line":36,"column":22},"end":{"line":36,"column":35}}}) : helper))) != null ? stack1 : "")
    + "\">\n          "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"skip_text") || (depth0 != null ? lookupProperty(depth0,"skip_text") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"skip_text","hash":{},"data":data,"loc":{"start":{"line":37,"column":10},"end":{"line":37,"column":25}}}) : helper))) != null ? stack1 : "")
    + "</label>\n      </span>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"registration-widget\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"error_message") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":6,"column":9}}})) != null ? stack1 : "")
    + "  <h5>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":7,"column":6},"end":{"line":7,"column":17}}}) : helper))) != null ? stack1 : "")
    + "</h5>\n  <input\n    type=\"text\"\n    id=\"r-"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"email_id") || (depth0 != null ? lookupProperty(depth0,"email_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"email_id","hash":{},"data":data,"loc":{"start":{"line":10,"column":10},"end":{"line":10,"column":24}}}) : helper))) != null ? stack1 : "")
    + "\"\n    name=\""
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"email_id") || (depth0 != null ? lookupProperty(depth0,"email_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"email_id","hash":{},"data":data,"loc":{"start":{"line":11,"column":10},"end":{"line":11,"column":24}}}) : helper))) != null ? stack1 : "")
    + "\"\n    placeholder=\""
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"placeholder_email") || (depth0 != null ? lookupProperty(depth0,"placeholder_email") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"placeholder_email","hash":{},"data":data,"loc":{"start":{"line":12,"column":17},"end":{"line":12,"column":40}}}) : helper))) != null ? stack1 : "")
    + "\"\n    class=\"registration-email-input\"\n  />\n  <div class=\"terms-agree-wrap\">\n    <input\n      type=\"checkbox\"\n      id=\"r-"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"terms_and_conds_id") || (depth0 != null ? lookupProperty(depth0,"terms_and_conds_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"terms_and_conds_id","hash":{},"data":data,"loc":{"start":{"line":18,"column":12},"end":{"line":18,"column":36}}}) : helper))) != null ? stack1 : "")
    + "\"\n      name=\""
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"terms_and_conds_id") || (depth0 != null ? lookupProperty(depth0,"terms_and_conds_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"terms_and_conds_id","hash":{},"data":data,"loc":{"start":{"line":19,"column":12},"end":{"line":19,"column":36}}}) : helper))) != null ? stack1 : "")
    + "\"\n      class=\"registration-tos-agree\"\n    />\n    <span>\n      <label for=\"r-"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"terms_and_conds_id") || (depth0 != null ? lookupProperty(depth0,"terms_and_conds_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"terms_and_conds_id","hash":{},"data":data,"loc":{"start":{"line":23,"column":20},"end":{"line":23,"column":44}}}) : helper))) != null ? stack1 : "")
    + "\">\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"terms_and_conditions_text") || (depth0 != null ? lookupProperty(depth0,"terms_and_conditions_text") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"terms_and_conditions_text","hash":{},"data":data,"loc":{"start":{"line":24,"column":8},"end":{"line":24,"column":39}}}) : helper))) != null ? stack1 : "")
    + "</label>\n    </span>\n  </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"skip") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":2},"end":{"line":40,"column":9}}})) != null ? stack1 : "")
    + "  <div class=\"join-btn-wrap\">\n    <button\n      id=\"join_button\"\n      class=\"btn-join\"\n      type=\"button\"\n    >\n    <span class=\"button-text\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"join_button_text") || (depth0 != null ? lookupProperty(depth0,"join_button_text") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"join_button_text","hash":{},"data":data,"loc":{"start":{"line":47,"column":30},"end":{"line":47,"column":52}}}) : helper))) != null ? stack1 : "")
    + "</span>\n    <div class=\"loader-button\">\n      <svg class=\"circular\" viewBox=\"25 25 50 50\">\n        <circle class=\"path\" cx=\"50\" cy=\"50\" r=\"20\" fill=\"none\" stroke-width=\"7\" stroke-miterlimit=\"10\"/>\n      </svg>\n    </div>\n    </button>\n  </div>\n</div>";
},"useData":true});