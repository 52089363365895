var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "slider-input-visible";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"ui-slider-handle rule-drag\" id=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"input_id") : depth0), depth0))
    + "-indicator\" style=\"opacity: 0\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"show_value") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":19,"column":19}}})) != null ? stack1 : "")
    + "            <div class=\"slider-indicator no-value\">\n              <div class=\"slider-indicator-line\"></div>\n              <div class=\"slider-indicator-dot\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"show_value") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":22,"column":48},"end":{"line":22,"column":214}}})) != null ? stack1 : "")
    + "></div>\n            </div>\n          </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <input\n              class=\"slider-input\"\n              type=\"number\"\n              aria-valuemin=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"min") : depth0), depth0))
    + "\"\n              aria-valuemax=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"max") : depth0), depth0))
    + "\"\n              tabindex=\"0\" />\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "";
},"8":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "role=\"slider\" tabindex=\"0\" aria-label=\"Draggable response value\" aria-valuemin=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"min") : depth0), depth0))
    + "\" aria-valuemax=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"max") : depth0), depth0))
    + "\" aria-valuenow=\"\"";
},"10":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"placement-drop\">\n              <div class=\"placement-slider\"></div>\n            </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"rule-level\"></div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"ui-slider-handle rule-drag\" id=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"input_id") : depth0), depth0))
    + "-indicator\" style=\"opacity: 0\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"show_value") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":12},"end":{"line":54,"column":19}}})) != null ? stack1 : "")
    + "            <div class=\"slider-indicator\">\n              <div class=\"slider-indicator-line\"></div>\n              <div data-testid=\"rule-slider-indicator-dot\" class=\"slider-indicator-dot\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"show_value") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":57,"column":88},"end":{"line":57,"column":254}}})) != null ? stack1 : "")
    + "></div>\n            </div>\n          </div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <input\n              class=\"slider-input\"\n              type=\"number\"\n              data-testid=\"rule-slider-input\"\n              aria-valuemin=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"min") : depth0), depth0))
    + "\"\n              aria-valuemax=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"max") : depth0), depth0))
    + "\"\n              tabindex=\"0\" />\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"rule-table "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"widget") : depth0), depth0))
    + "\">\n    <div role=\"status\" class=\"sr-only\" tabindex=\"0\">\n      Slider element with the minimum value "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"min") : depth0), depth0))
    + " labelled as \""
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"left_message") : depth0), depth0)) != null ? stack1 : "")
    + "\", and the maximum value "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"max") : depth0), depth0))
    + " labelled as \""
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"right_message") : depth0), depth0)) != null ? stack1 : "")
    + "\"\n    </div>\n    <div class=\"rule-inner "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"show_value") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":27},"end":{"line":5,"column":72}}})) != null ? stack1 : "")
    + "\">\n    <div class=\"responsive-grid responsive-grid-wide\">\n      <div class=\"responsive-cell left-message\" aria-hidden=\"true\"><div>"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"left_message") : depth0), depth0)) != null ? stack1 : "")
    + "</div></div>\n        <div class=\"responsive-cell center-widget rule-main-cell\">\n        <div class=\"rule-ruler\" id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"input_id") : depth0), depth0))
    + "\">\n"
    + ((stack1 = __default(require("../js/helpers/ifequal.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"widget") : depth0),"rule",{"name":"ifequal","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":10},"end":{"line":25,"column":22}}})) != null ? stack1 : "")
    + "          <div class=\"rule-levels\">\n"
    + ((stack1 = __default(require("../js/helpers/ifequal.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"widget") : depth0),"placement",{"name":"ifequal","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":12},"end":{"line":31,"column":24}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../js/helpers/forloop.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"min") : depth0),(depth0 != null ? lookupProperty(depth0,"max") : depth0),(depth0 != null ? lookupProperty(depth0,"increment") : depth0),{"name":"forloop","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":12},"end":{"line":34,"column":24}}})) != null ? stack1 : "")
    + "          </div>\n        </div>\n      </div>\n      <div data-testid=\"rule-right-message\" class=\"responsive-cell right-message\" aria-hidden=\"true\"><div>"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"right_message") : depth0), depth0)) != null ? stack1 : "")
    + "</div></div>\n    </div>\n    <div class=\"responsive-grid responsive-grid-skinny\">\n      <div data-testid=\"rule-left-message\" class=\"responsive-cell left-message\" aria-hidden=\"true\"><div>"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"left_message") : depth0), depth0)) != null ? stack1 : "")
    + "</div></div>\n        <div class=\"responsive-cell center-widget rule-main-cell-skinny\">\n        <div class=\"rule-ruler\" id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"input_id") : depth0), depth0))
    + "\">\n"
    + ((stack1 = __default(require("../js/helpers/ifequal.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"widget") : depth0),"rule",{"name":"ifequal","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":10},"end":{"line":60,"column":22}}})) != null ? stack1 : "")
    + "          <div class=\"rule-levels\">\n"
    + ((stack1 = __default(require("../js/helpers/ifequal.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"widget") : depth0),"placement",{"name":"ifequal","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":62,"column":12},"end":{"line":66,"column":24}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../js/helpers/forloop.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"min") : depth0),(depth0 != null ? lookupProperty(depth0,"max") : depth0),(depth0 != null ? lookupProperty(depth0,"increment") : depth0),{"name":"forloop","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":67,"column":12},"end":{"line":69,"column":24}}})) != null ? stack1 : "")
    + "          </div>\n        </div>\n      </div>\n      <div class=\"responsive-cell right-message\" aria-hidden=\"true\"><div>"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"right_message") : depth0), depth0)) != null ? stack1 : "")
    + "</div></div>\n    </div>\n  </div>\n</div>";
},"useData":true});