import __ from '../localization';

export const report = {
  report: function (title, message, level, hideContent) {
    if (!title && !message) {
      // There is nothing to display.
      return;
    }

    var levels = ['info', 'success', 'warning', 'error'];

    if (levels.indexOf[level] === -1) {
      level = 'info';
    }

    $('#pageLoad').hide();
    $('#mainNav').hide();
    $('.spd-content').hide();

    if (hideContent) {
      $('#main_cont').hide();
    }

    var titleHtml = title ? '<h4>' + title + '</h4>' : '';
    var messageHtml = message ? '<p>' + message + '</p>' : '';

    $('#notification')
      .attr('class', 'alert alert-' + level)
      .html(titleHtml + messageHtml)
      .show();
  },
  info: function (title, message, hideContent) {
    this.report(title, message, 'info', hideContent);
  },
  success: function (title, message, hideContent) {
    this.report(title, message, 'success', hideContent);
  },
  warning: function (title, message, hideContent) {
    this.report(title, message, 'warning', hideContent);
  },
  error: function (title, message, hideContent) {
    this.report(title, message, 'error', hideContent);
  },
  logError: function (error, silent = false) {
    if (window.newrelic) {
      //selective supressing the following error
      var shouldIgnoreError = function (error) {
        var knownErrorToSuppress = [
          'privateSpecialRepair is not defined',
          'pollfish',
        ];
        return knownErrorToSuppress.some(function (knownError) {
          var regEx = new RegExp(knownError, 'gi');
          return regEx.test(error);
        });
      };

      newrelic.setErrorHandler(function (error) {
        if (shouldIgnoreError(error)) return true;
        return false;
      });
    }

    function getNestedProp(obj, path, defaultValue = '') {
      return path.reduce(
        (acc, key) => (acc && acc[key] !== undefined ? acc[key] : defaultValue),
        obj
      );
    }

    try {
      if (error && error.message.indexOf('ajax') === -1) {
        var errinfo = {
          // Error details
          'Error Message': error?.message || '',
          'Error Name': error?.name || '',
          'Error Stack': error?.stack || '',
          'Error Type': error?.type || '',
          'Error Code': error?.code || '',
          'Error Cause': error?.cause || '',
          'Error Filename': error?.fileName || '',

          // Nested error cause details
          'Error Cause Nested Message': getNestedProp(error, [
            'error',
            'cause',
            'message',
          ]),
          'Error Cause Nested Stack': getNestedProp(error, [
            'error',
            'cause',
            'stack',
          ]),
          'Error Cause Message': error?.cause?.message || '',
          'Error Cause Stack': error?.cause?.stack || '',
          'Error Nested': error?.error || '',
          'Error Nested Stack': error?.error?.stack || '',

          // Page and browser-related info
          URL: error?.url || window?.location?.href,
          'Line No': error?.lineNumber || '',
          'Column No': error?.columnNumber || '',

          // Custom data
          Silent: silent ? 'yes' : 'no',
          'Page Name': window?.page_state?.this_page || '',
          UUID: window?.page_state?.uuid || '',
          'Tracking Data': window?.page_state?.tracking_data || '',
          Referrer: document?.referrer || '',

          // Viewport information
          'Viewport Width': window?.innerWidth || '',
          'Viewport Height': window?.innerHeight || '',
          'Screen Orientation': screen?.orientation?.type || '',
        };

        // If Error contains a cause, append to the
        // errinfo object for New Relic tracking
        if (error.error.cause) {
          errinfo[error.error.cause] = true;
        }

        if (!segments) {
          segments = /preview/.test(window.location.pathname)
            ? window.location.pathname.replace('/preview', '')
            : window.location.pathname;
        }
        var pathstart = segments.replace('#', '');
        var pathparts = pathstart.split('/');

        // If URL starts with / first element is empty.
        if (pathstart.indexOf('/') === 0) {
          pathparts = pathparts.slice(1);
        }

        // survey_name and survey_version is used only in preview mode
        var querystring = [
          'visa=' + pathparts[0],
          'survey_name=' + pathparts[1],
          'survey_version=' + pathparts[2],
        ].join('&');

        if (window.newrelic) {
          var errObj = error.error
            ? error.error
            : new Error(JSON.stringify(errinfo));

          newrelic.noticeError(errObj, errinfo);
        }
      }
    } catch (err) {}

    if (!window.is_production && window.console) {
      console.error(error);
    }

    if (silent) {
      // Don't inform the user.
      return;
    }

    const oopsTitle = __.localize('Oops! Something went wrong!');
    const oopsMessage = __.localize(
      'We sincerely apologize for the inconvenience. Please refresh your browser or check back later to resume your survey.'
    );

    // Log the error into the EventLog
    if (typeof eventLog !== 'undefined') {
      var splitUrl = window.location.href.split('/');
      eventLog.log({
        application: 'gryphon.ivw',
        version: window.appVersion,
        event: 'oops-error',
        environment: window.NODE_ENV,
        event_time: Date(),
        visa: splitUrl[splitUrl.length - 1],
        url: window.location.href,
        error: {
          title: error.message.includes('ajax')
            ? 'Failed network request (AJAX)'
            : oopsTitle,
          message: oopsMessage,
        },
        user_agent: navigator.userAgent,
      });
    }

    if (error.status && error.status === 404) {
      document.title = '404 Page Not Found';
      var template = Gryphon.templates['404'];
      return $('#main_cont').html(template);
    }

    this.error(oopsTitle, oopsMessage, true);
  },
};

window.report = report;
