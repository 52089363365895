(function (widgets) {
  var IS_TOUCH = (function () {
    var check = false;
    /* eslint-disable no-useless-escape*/
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      ) {
        check = true;
      }
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  })();

  var Upload = widgets.Widget.extend(
    {
      render: function render() {
        Upload.__super__.render.call(this);
        var self = this,
          object = this._object,
          form = (this.form = this.$el.closest('form'));
        self.clickEvent = 'click keypress';
        form.attr('enctype', 'multipart/form-data');
        self.fileBuffer = [];
        self.errorList = [];

        // Uniform styling for DK input checkbox
        this.$el.find('input:checkbox').uniform();

        // [GRYP-9785]
        // required=SOFT will submit to the back-end and can
        // return an 'error_message' value, unhide in this case
        if (self._object.error_message !== '') {
          $('.alert-error').removeClass('hide').show();
        }

        $.validator.methods.filemissing = function (value, element, param) {
          element = self._object;
          if ($('#r-' + self._object.dk_id).is(':checked')) {
            return true;
          }
          if (element.required === 'HARD' && !element.files) {
            return false;
          } else {
            return true;
          }
        };

        this.validator = form.validate({
          onfocusin: function () {
            // Hide any alerts when clicking on the "Choose file to upload" button
            $('.alert-error').hide();
            self.errorList = [];
          },
          showErrors: function (errorMap, errorList) {
            // Combine with existing `errorList` array
            self.errorList = self.errorList.concat(errorList);
            self._reset(errorList);
            if (errorList.length > 0) {
              var err_msg = '';
              for (var i = 0; i < errorList.length; i++) {
                err_msg += errorList[i].message;
              }
              $('.alert-error').removeClass('hide').text(err_msg).show();
              $(window).resize();
            }
          },
          submitHandler: function (element, event) {},
        });
        $('#r-upload-' + this._object.input_id).rules('add', {
          filemissing: 1,
          messages: {
            // [GRYP-9785] Use 'required_text' if provided
            filemissing: jQuery.validator.format(
              self._object.required_text
                ? self._object.required_text
                : self._object.min_number_text
            ),
          },
        });
        if (IS_TOUCH) {
          // we don't need to display the label for drag and drop on mobile
          $('.input-container label').hide();
          self.clickEvent = 'touchend';
        }

        this._events(self);
      },

      _reset: function _reset(errorList) {
        var self = this;
        if (!errorList) {
          $('.file-list').hide().text('');
          $('.alert-error').hide();
        }
        self.fileBuffer =
          typeof self._object.files === 'object' ? self._object.files : [];
        if (typeof errorList === 'boolean' && errorList === false) {
          $('#r-upload-' + this._object.input_id)[0].value = '';
        }
      },

      _events: function _events(self) {
        $(self.$el)
          .find('button[role="button"]')
          .on(self.clickEvent, function (event) {
            $('#r-upload-' + self._object.input_id).trigger('click');
          });

        // When file input changes, auto focus out so our validations can be initialized
        $('#r-upload-' + self._object.input_id).on(
          'change',
          function (event, files, type) {
            self.fileBuffer =
              typeof self._object.files === 'object' &&
              (!files || type === 'onDrop')
                ? self._object.files
                : [];
            var filesBuffer = files ? files : $(this)[0].files;

            if (type === 'onDelete') {
              self._object.files = self.fileBuffer;
              self.fileBuffer = [];
              self.errorList = [];
            }

            if (!self._validate('maxnumber', filesBuffer)) return;

            self._validate('filesize', filesBuffer);
            self._validate('filetype', filesBuffer);

            Array.prototype.push.apply(self.fileBuffer, filesBuffer);
            self._object.files = self.fileBuffer;
            var valid = self.validator.element(
              '#r-upload-' + self._object.input_id
            );
            if (!valid) {
              self._object.files = [];
              return;
            }

            self._displayFiles(self.fileBuffer);

            // Disable the `Choose file to upload` button when limit reached
            self._toggleInputButtonState(
              self.dropbox.find('.response-button'),
              !(self._object.files.length >= self._object.max_number)
            );
            self._toggleNextButtonState(
              self._object.files.length > 0 && !self._errorsInFiles()
                ? 'upload'
                : 'next'
            );

            // If we have added new files since our last click of "Upload",
            // we'll need to revalidate the new files and upload them as well.
            // This resets the behavior of the "Next" button back to "Upload"
            if (self.completed && self.completed < self._object.files.length) {
              mainNav.nextButton.bind(
                self.clickEvent,
                $.proxy(self, '_upload')
              );
            }
          }
        );

        // Handle Don't Know clicks
        if (self._object.dk && self._object.dk_id) {
          $('#r-' + self._object.dk_id).on(self.clickEvent, onClickDK);
          handle_keyboard_checkbox('#r-' + self._object.dk_id);
        }

        if (!IS_TOUCH) {
          // Drag and drop
          var dropbox;
          dropbox = document.getElementById(
            'upload-dropbox-' + self._object.input_id
          );
          dropbox.addEventListener('dragenter', onDragEnter, false);
          dropbox.addEventListener('dragleave', onDragLeave, false);
          dropbox.addEventListener('drop', onDrop, false);
          document.addEventListener('dragover', function (e) {
            e.preventDefault();
          });
        }

        self.dropbox = $('#upload-dropbox-' + self._object.input_id);

        function onDragEnter(e) {
          if (
            self._object.files &&
            self._object.files.length >= self._object.max_number
          ) {
            return;
          }
          e.stopPropagation();
          e.preventDefault();
          self.dropbox.css({
            border: '1px solid var(--color-stonegrey-300)',
            'background-color': 'var(--color-stonegrey-300)',
          });
          self.dropbox.find('.response-button').css({
            'background-color': 'var(--color-blueberry-1100)',
          });
        }

        function onDragLeave(e) {
          if (
            e.target.className === 'upload-container' ||
            (self._object.files &&
              self._object.files.length >= self._object.max_number)
          ) {
            return;
          }
          e.stopPropagation();
          e.preventDefault();
          self.dropbox.css({
            border: '1px dashed var(--color-stonegrey-900)',
            'background-color': 'var(--color-white)',
          });
          self.dropbox.find('.response-button').css({
            'background-color': 'var(--color-blueberry-1000)',
          });
        }

        function onDrop(e) {
          e.stopPropagation();
          e.preventDefault();

          var dt = e.dataTransfer,
            files = dt.files;

          self.dropbox.css({
            border: '1px dashed var(--color-stonegrey-900)',
            'background-color': 'var(--color-white)',
          });
          self.dropbox.find('.response-button').css({
            'background-color': 'var(--color-blueberry-1000)',
          });

          $('#r-upload-' + self._object.input_id).trigger('change', [
            files,
            'onDrop',
          ]);
        }

        // Only toggles the .file-list display, does not
        // actually remove the files from the form
        function onClickDK(e) {
          const input = e.currentTarget;
          const fileList = $(self.$el).find('.file-list');
          if (input.checked) {
            // Disable file list
            fileList.addClass('disabled');
          } else {
            // Enable file list
            fileList.removeClass('disabled');
          }
        }

        self.beforeUnloadHandler = function (event) {
          // Back button
          if (event.constructor.name === 'Event') {
            if (self._object.files && self._object.files.length > 0) {
              $('#unsavedModal').modal('show');
              $('#unsavedModalLeaveBtn').on('click', function () {
                $('#unsavedModal').modal('hide');
                self._toggleNextButtonState('next');
                click_back({});
              });
            } else {
              self._toggleNextButtonState('next');
              click_back({});
            }
            return;
          }

          // Reload page
          if (!self._object.files || self._object.files.length === 0) {
            // No files yet, reload / navigate as normal
            return;
          } else {
            // Shows browser dialog
            // Note: The message is not customizable per security restrictions in modern browsers.
            // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event#usage_notes
            event.preventDefault();
            event.returnValue = ''; // legacy browsers
            return '';
          }
        };
        window.addEventListener('beforeunload', self.beforeUnloadHandler);

        mainNav.nextButton.bind(self.clickEvent, $.proxy(self, '_upload'));
        mainNav.backButton.bind(self.clickEvent, function () {
          window.dispatchEvent(new Event('beforeunload'));
        });
      },

      _bindRemove: function _bindRemove() {
        var self = this;

        $('.file-list .file-list-item button.btn-remove')
          .off(self.clickEvent)
          .on(self.clickEvent, function (event) {
            const rowElement = $(event.currentTarget).closest(
              '.file-list-item-wrapper'
            );
            const rowIndex = $(this).data('idx');

            // Add our file row element to the modal
            $('#deleteModal .modal-body .file-list-modal').html(
              '<div class="file-list-item-wrapper">' +
                $(rowElement).html() +
                '</div>'
            );

            // Display the delete confirmation modal
            $('#deleteModal').modal({
              backdrop: 'static', // [mobile] Ensure clicks on the 'overlay' do not close our modal by accident
              show: true,
            });

            // Event listener for "Yes, delete"
            $('#deleteModalConfirmBtn')
              .off(self.clickEvent)
              .on(self.clickEvent, function () {
                $('#deleteModal').modal('hide');

                // Delete file upon confirmation
                if ($('#file-row-' + rowIndex)) {
                  let idx = $('.file-list-item-wrapper').index(
                    $('#file-row-' + rowIndex)
                  );
                  $('#file-row-' + rowIndex).remove();
                  self._object.files.splice(idx, 1);
                }
                if (self._object.files.length === 0) {
                  self._reset(false);
                  self._toggleInputButtonState(
                    self.dropbox.find('.response-button'),
                    true
                  );
                  self._toggleNextButtonState('next');
                } else {
                  self._toggleInputButtonState(
                    self.dropbox.find('.response-button'),
                    !(self._object.files.length >= self._object.max_number)
                  );

                  /**
                   * After removing a file, if the number of completed uploads
                   * is equal to the number of current files, then we can progress
                   * to the next question on click of Next button.
                   *
                   * Otherwise, switch the button to "Upload" and allow for additional
                   * file uploads to occur until limit
                   */
                  if (self.completed === self._object.files.length) {
                    self._toggleNextButtonState('next');
                    mainNav.nextButton.bind(self.clickEvent, click_next);
                  } else {
                    self._toggleNextButtonState('upload');
                    mainNav.nextButton.bind(
                      self.clickEvent,
                      $.proxy(self, '_upload')
                    );
                  }
                }
              });
          });
      },

      /**
       * Handles the visual state and presentation of the File input button
       *
       * @param {HTMLElement} button A jQuery element representing the Input Button
       * @param {Boolean} enable A boolean value passed to indicate if the button should be enabled or disabled
       */
      _toggleInputButtonState: function (button, enable) {
        if (enable) {
          $('.input-container label').css('color', '#000');
          button.removeClass('response-button-disabled');
          button.removeAttr('disabled');
        } else {
          $('.input-container label').css(
            'color',
            'var(--color-stonegrey-800)'
          );
          button.addClass('response-button-disabled');
          button.attr('disabled', 'disabled');
        }
      },

      /**
       * Handles the visual state and presentation of the Next / Upload navigation button
       * Note: Button action is NOT rebound in this function, that is done ad-hoc
       *
       * @param {String} state The state the Next button should be in (Upload, Uploading, or Next)
       */
      _toggleNextButtonState: function (state) {
        const self = this;
        const button = document.getElementById(mainNav.nextButton.id);

        // Disable the next button when we've got active `errors` in the `files`
        if (self._errorsInFiles() && self._object.files.length > 0) {
          $(button).attr({ disabled: 'disabled', 'aria-disabled': true });
        } else {
          $(button).removeAttr('disabled').attr({ 'aria-disabled': false });
        }

        // Only switch state if the state is different from the current one
        if (button.name.toLowerCase() === state) return;

        switch (state) {
          case 'upload':
            button.name = 'Upload';
            if ($(button).find('.uploadState').length > 0) {
              $(button)
                .find('.uploadState')
                .text(`${self._object.upload_text}`);
            } else {
              $(button)
                .addClass('upload-button')
                .prepend(
                  `<span class="uploadState">${self._object.upload_text}</span>`
                );
            }
            break;
          case 'uploading':
            button.name = 'Uploading';
            $(button).find('.uploadState').text(`${self._object.uploading}`);
            self._toggleInputButtonState(
              self.dropbox.find('.response-button'),
              false
            );
            break;
          case 'next':
          default:
            button.name = 'Next';
            $(button)
              .removeClass('upload-button')
              .find('.uploadState')
              .remove();
            self._toggleInputButtonState(
              self.dropbox.find('.response-button'),
              true
            );
            break;
        }
      },

      _displayFiles: function _displayFiles(files) {
        var self = this;
        $('.alert-error').hide();
        if (!files || files.length === 0) {
          self._toggleNextButtonState('next');
          return;
        }
        $(self.$el).find('.file-list').show().text('');
        for (var i = 0; i < files.length; i++) {
          let file = files[i];

          // Add file to list
          $(self.$el).find('.file-list').append(self._getFileListItem(i, file));

          // Add thumbnail of file contents
          let imageSelector = `file-row-${i}-preview`;
          self._generatePreviewThumbnail(file, imageSelector);
        }

        self._bindRemove();
      },

      _errorsInFiles: function () {
        return (
          document.getElementsByClassName('file-list-item-error').length > 0
        );
      },

      _getFileListItem: function (i, file) {
        return `
          <div class="file-list-item-wrapper ${
            file.error ? 'file-list-item-error' : ''
          }" id="file-row-${i}">
            <div class="file-list-item">
              <div class="file-list-item-preview">
              ${
                /video\//g.test(file.type)
                  ? `<div id="file-row-${i}-preview" />`
                  : /image\//g.test(file.type)
                  ? `<img id="file-row-${i}-preview" alt="Preview thumbnail" />`
                  : `<i id="file-row-${i}-preview" class="fas fa-stream"></i>`
              }
              </div>
              <div class="file-list-item-metadata">
                <div class="file-list-item-name">${file.name}</div>
                <div class="file-list-item-filesize">${parseFloat(
                  file.size / (1024 * 1024)
                ).toFixed(2)} MB</div>
              </div>
              <div class="file-list-item-error-msg">${
                file.error && !IS_TOUCH ? '<p>' + file.error + '</p>' : ''
              }</div>
              <div class="file-list-item-actions">
                <button class="btn-remove ${
                  file.uploaded ? 'hide' : ''
                }" type="button" data-idx="${i}" title="${
          this._object.upload_delete
        }">
                  <span class="ygicon-popup-close"></span>
                </button>
                <button class="btn-retry" type="button" data-idx="${i}" title="${
          this._object.upload_retry
        }" style="display: ${file.error ? 'inline-block' : 'none'}">
                  <span class="fas fa-redo"></span>
                </button>
                <i class="fas fa-check-circle uploaded" style="display: ${
                  file.uploaded ? 'block' : 'none'
                }"></i>
              </div>
            </div>
            <div class="file-list-item-error-msg file-list-item-error-msg-mobile" style="display: ${
              file.error && IS_TOUCH ? 'block' : 'none'
            }">${
          file.error && IS_TOUCH ? '<p>' + file.error + '</p>' : ''
        }</div>
            <div class="file-list-item-progress" style="display: none">
              <div class="progress">
                <div class="bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;">
                  <span class="sr-only">0% ${
                    this._object.upload_completed_perc
                  }</span>
                </div>
              </div>
              <div class="percent">0%</div>
            </div>
          </div>
        `;
      },

      _generatePreviewThumbnail: function (file, selector) {
        let previewSrc;

        function useDefaultIcon(selector) {
          document.getElementById(
            selector
          ).parentElement.innerHTML = `<i class="fas fa-stream"></i>`;
        }

        if (/image\//g.test(file.type)) {
          try {
            // Image files
            let reader = new FileReader();

            reader.onload = function (e) {
              previewSrc = e.target.result;
              let imageElement = document.getElementById(selector);
              if (imageElement) imageElement.src = previewSrc;
            };

            // Up to first 50MB of the file as a blob for the preview
            let blob = file.slice(0, 1024 * 1024 * 50, file.type);

            reader.readAsDataURL(blob);
          } catch (e) {
            useDefaultIcon(selector);
          }
        } else if (/video\//g.test(file.type)) {
          try {
            // Video files
            let videoElement = document.createElement('video');
            videoElement.src = URL.createObjectURL(file);

            videoElement.onerror = function () {
              useDefaultIcon(selector);
            };

            // Append to preview container
            document.getElementById(selector).appendChild(videoElement);
          } catch (e) {
            useDefaultIcon(selector);
          }
        }
      },

      _uploadToS3: async function (uploadState, file, listIndex) {
        const self = this;

        try {
          const formData = new FormData();

          // Add FormData fields based on the response from the API
          for (const [key, value] of Object.entries(
            uploadState.fileState.presignedURLUploadFormFields
          )) {
            formData.append(key.toString(), value.toString());
          }

          // Add a FormData field for the File object
          formData.append('file', file);

          document.querySelector(
            `#file-row-${listIndex} .file-list-item-progress`
          ).style.display = 'flex';
          let progressBar = document.querySelector(
            `#file-row-${listIndex} .file-list-item-progress div[role="progressbar"]`
          );
          let progressPercent = document.querySelector(
            `#file-row-${listIndex} .file-list-item-progress .percent`
          );

          const xhr = new XMLHttpRequest();
          const pending = await new Promise((resolve, reject) => {
            xhr.upload.addEventListener('abort', () => {
              // Cancelable uploads
            });

            xhr.upload.addEventListener('error', (event) => {
              // The upload failed due to an error
              reject(event);
            });

            xhr.upload.addEventListener('progress', (event) => {
              if (event.lengthComputable) {
                const progressValue = (
                  (event.loaded / event.total) *
                  100
                ).toFixed(0);
                progressBar.style.width = progressValue + '%';

                // Accessibility
                progressBar.ariaValueNow = progressValue;
                progressBar.getElementsByClassName(
                  'sr-only'
                ).innerHTML = `${progressValue}% complete`;

                progressPercent.innerHTML = progressValue + '%';
              }
            });

            xhr.upload.addEventListener('retry', () => {
              // Reset file state back to default and retry the upload
              let row = $(`#file-row-${listIndex}`);
              if (row.hasClass('file-list-item-error')) {
                row.removeClass('file-list-item-error');
                document.querySelector(
                  `#file-row-${listIndex} .file-list-item-actions .btn-retry`
                ).style.display = 'none';
                document.querySelector(
                  `#file-row-${listIndex} .file-list-item-error-msg`
                ).innerHTML = '';
              }

              self._uploadToS3(uploadState, file, listIndex);
            });

            xhr.addEventListener('load', () => {
              if (
                xhr.readyState === 4 &&
                (xhr.status === 200 || xhr.status === 204)
              ) {
                resolve(true);
              } else if (xhr.status >= 400) {
                reject(true);
              }
            });

            xhr.open('POST', uploadState.fileState.presignedURLUpload, true);
            xhr.send(formData);
          })
            .then(() => {
              // Success
              file.uploaded = true;
              $(`#file-row-${listIndex}`).removeClass('file-list-item-error');

              // Hide remove / retry buttons and show a checkmark icon instead
              document.querySelector(
                `#file-row-${listIndex} .file-list-item-actions .btn-remove`
              ).style.display = 'none';
              document.querySelector(
                `#file-row-${listIndex} .file-list-item-actions .btn-retry`
              ).style.display = 'none';
              document.querySelector(
                `#file-row-${listIndex} .file-list-item-actions .uploaded`
              ).style.display = 'block';
              document.querySelector(
                `#file-row-${listIndex} .file-list-item-progress`
              ).style.display = 'none';

              // On success, update our hidden input used to save data in the API
              self.inputVal.push(uploadState.fileState.fileLocation);
              $('#r-' + self._object.input_id).val(self.inputVal.toString());

              // Increment the number of completed uploads
              self.completed++;

              // If the number of complete uploads is equal to the number
              // of files, we can auto advance to the next question
              if (self.completed === self._object.files.length) {
                self._toggleNextButtonState('next');
                mainNav.nextButton.bind(self.clickEvent, click_next);
              }
            })
            .catch(() => {
              // Error
              let row = $(`#file-row-${listIndex}`);
              let retryBtn = row.find('button.btn-retry');
              let currentUTC = new Date().toUTCString();
              let current = new Date(currentUTC);
              let expired = new Date(
                uploadState.fileState.presignedURLUploadExpiration
              );
              file.error = `${self?._object?.upload_error}`;

              if (!row.hasClass('file-list-item-error')) {
                row.addClass('file-list-item-error');
                if (!IS_TOUCH) {
                  row
                    .find(
                      '.file-list-item-error-msg:not(.file-list-item-error-msg-mobile)'
                    )
                    .html(`<p>${self?._object?.upload_error}</p>`);
                } else {
                  row
                    .find('.file-list-item-error-msg-mobile')
                    .html(`<p>${self?._object?.upload_error}</p>`)
                    .show();
                }

                // Check that our file state object is not expired
                if (current.valueOf() < expired.valueOf()) {
                  retryBtn.show();
                } else {
                  retryBtn.hide();
                }
              }

              // Retry button click handler
              retryBtn.off(self.clickEvent).on(self.clickEvent, () => {
                xhr.upload.dispatchEvent(new CustomEvent('retry'));
              });
            });
        } catch (e) {
          self._toggleNextButtonState('next');
          $('.alert-error').html(self?._object?.upload_error).show();
        }
      },

      _upload: function _upload(ev) {
        if (
          ev &&
          ev.type === 'keypress' &&
          ev.key !== ' ' &&
          ev.key !== 'Enter'
        ) {
          return;
        }
        var self = this;
        self.inputVal = self.inputVal || [];
        self.completed = self.completed || 0;

        if (!self._validate('minnumber')) {
          $('.alert-error')
            .removeClass('hide')
            .text(
              self._object.min_number_text.replace(
                '{0}',
                self._object.min_number
              )
            )
            .show();
          return;
        }
        if (!self.form.valid()) {
          return;
        }
        if (
          !self._object.files ||
          self._object.files.length === 0 ||
          $(self.$el).find('.file-list').hasClass('disabled')
        ) {
          click_next({ key: ev.key, type: ev.type });
          return;
        }

        function onLoadEnd(e) {
          try {
            const res = JSON.parse(this.response);

            // Upload Validation Errors
            if (res.uploadErrors || res.overallErrors) {
              let alertMessage = ``;
              if (res.overallErrors && res.overallErrors.length > 0) {
                res.overallErrors.forEach((overallError) => {
                  alertMessage += `<dt>${overallError.description}</dt>`;
                });
              }

              if (res.uploadErrors && res.uploadErrors.length > 0) {
                res.uploadErrors.forEach((uploadError) => {
                  let msg = '';
                  let row = $(
                    `.file-list-item-name:contains('${uploadError.fileDescriptor.name}')`
                  ).parents('.file-list-item-wrapper');
                  if (!row.hasClass('file-list-item-error')) {
                    switch (uploadError.errors[0].type) {
                      case 'MimeTypeNotAllowedError':
                        msg = `${self._object.wrong_file_format}`;
                        break;
                      case 'FileExtensionNotAllowedError':
                        msg = `${self._object.wrong_file_format}`;
                        break;
                      case 'FileSizeLimitExceededError':
                        msg = `${self._object.wrong_file_size}`;
                        break;
                      default:
                        msg = `${self._object.upload_error}`;
                        break;
                    }

                    if (!IS_TOUCH) {
                      row
                        .addClass('file-list-item-error')
                        .find(
                          '.file-list-item-error-msg:not(.file-list-item-error-msg-mobile)'
                        )
                        .html(`<p>${msg}</p>`);
                    } else {
                      row
                        .addClass('file-list-item-error')
                        .find('.file-list-item-error-msg-mobile')
                        .html(`<p>${msg}</p>`)
                        .show();
                    }
                  }
                });
              }

              // Show the alert with the validation messages from the API
              if (alertMessage !== '') {
                $('.alert-error').html(`<dl>${alertMessage}</dl>`).show();
              }

              return;
            }

            // Upload Validation Success
            if (res.uploadStates) {
              res.uploadStates.forEach(async (uploadState, index) => {
                try {
                  if (!self._object.files[index].uploaded) {
                    self._uploadToS3(
                      uploadState,
                      self._object.files[index],
                      index
                    );
                  }
                } catch (e) {
                  $('.alert-error')
                    .html(`${self?._object?.upload_error}`)
                    .show();
                }
              });
            }
          } catch (exc) {
            self._toggleNextButtonState('next');
            $('.alert-error').html(`${self?._object?.upload_error}`).show();
          }
        }

        let xhr = new XMLHttpRequest();
        xhr.withCredentials = true;
        xhr.crossDomain = true;

        // Local Development uses a Proxy
        var uploadUrl =
          window.context_name === 'preview'
            ? `/preview/_upload/v2/${page_state.preview_data.visa}/${page_state.preview_data.survey}/${page_state.preview_data.version}`
            : `/_upload/v2`;

        // Everything else expects to be given the base URL
        if (
          process.env.ENVIRONMENT !== 'development' &&
          process.env.NODE_ENV !== 'test'
        ) {
          uploadUrl =
            window.context_name === 'preview'
              ? `${
                  localStorage.getItem('ivw_be')
                    ? localStorage.getItem('ivw_be')
                    : process.env.PROXY_GRYPHON_API_URL
                }/preview/_upload/v2/${page_state.preview_data.visa}/${
                  page_state.preview_data.survey
                }/${page_state.preview_data.version}`
              : `${process.env.PROXY_IVW_URL}/_upload/v2`;
        }

        // Open a connection to the IVW API
        xhr.open('POST', uploadUrl, true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.addEventListener('loadend', onLoadEnd);

        let reqPayload = {
          files: [],
          variable: self._object.varname,
        };

        for (var i = 0; i < self._object.files.length; i++) {
          // Add each file to the `files` array sent to the backend for validation
          reqPayload.files.push({
            name: self._object.files[i].name,
            mimeType: self._object.files[i].type,
            size: self._object.files[i].size,
          });
        }

        // Get a pre-signed S3 URL from the API for each file
        xhr.send(JSON.stringify(reqPayload));

        self._toggleNextButtonState('uploading');
      },

      _validate: function (validationType, filesBuffer) {
        const self = this;

        if (validationType === 'maxnumber') {
          if (
            (self._object.files &&
              filesBuffer &&
              parseInt(filesBuffer.length) +
                parseInt(self._object.files.length) >
                parseInt(self._object.max_number)) ||
            (filesBuffer &&
              parseInt(filesBuffer.length) > parseInt(self._object.max_number))
          ) {
            $('.alert-error')
              .html(
                self._object.max_number_text.replace(
                  '{0}',
                  self._object.max_number
                )
              )
              .show();
            return false;
          } else {
            return true;
          }
        }

        if (validationType === 'minnumber') {
          if ($('#r-' + self._object.dk_id).is(':checked')) {
            return true;
          }
          if (self._object.required === 'HARD') {
            if (self._object.files) {
              if (
                self._object.min_number > 0 &&
                self._object.files.length < self._object.min_number
              ) {
                return false;
              }
              return (
                parseInt(self._object.files.length) >= self._object.min_number
              );
            }
            return false;
          } else {
            return true;
          }
        }

        if (validationType === 'filesize') {
          if (filesBuffer.length > 0) {
            for (i = 0; i < filesBuffer.length; i++) {
              let file = filesBuffer[i];

              // Grab the filesize of each file (in megabytes)
              if (
                parseFloat(file.size / (1024 * 1024)) >
                self._object.max_filesize_MB
              ) {
                file.error = `${self._object.wrong_file_size}`;
                self.errorList.push({ message: file.error });
              }
            }
          }
        }

        if (validationType === 'filetype') {
          let param = self._object.file_types;
          param =
            typeof param === 'string'
              ? param.replace(/\s/g, '').replace(/,/g, '|')
              : 'image/*,video/*,text/*';

          if (filesBuffer.length > 0) {
            param = param.replace(/\*/g, '.*');

            for (var i = 0; i < filesBuffer.length; i++) {
              let file = filesBuffer[i];
              if (!file.type.match(new RegExp('.?(' + param + ')$', 'i'))) {
                $('.alert-error').html(self._object.filetype_text).show();
                file.error = `${self._object.wrong_file_format}`;
                self.errorList.push({ message: file.error });
              }
            }
          }
        }
      },

      destroy: function destroy() {
        Upload.__super__.destroy.call(this);
        window.removeEventListener('beforeunload', this.beforeUnloadHandler);
      },
    },
    {
      types: ['upload'],
      views: ['upload'],
    }
  );

  Upload.register();
  widgets.Upload = Upload;
})((Gryphon.widgets = Gryphon.widgets || {}));
