var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + ((stack1 = __default(require("../js/helpers/ifcond.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"qtext_len") : depth0),">",1,{"name":"ifcond","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":3,"column":33},"end":{"line":5,"column":53}}})) != null ? stack1 : "")
    + " ";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"qtext") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":61},"end":{"line":5,"column":21}}})) != null ? stack1 : "")
    + " ";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n    "
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"qtext") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":5,"column":13}}})) != null ? stack1 : "")
    + " ";
},"4":function(container,depth0,helpers,partials,data) {
    return " "
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "<br />\n    ";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0)) != null ? stack1 : "")
    + " ";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0)) != null ? stack1 : "")
    + "\n";
},"10":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div role=\"status\" class=\"sr-only\" tabindex=\"0\" id=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"input_id") : depth0), depth0))
    + "_note\">\n    Previous answer is \n"
    + ((stack1 = __default(require("../js/helpers/ifequal.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"type") : depth0),"single-colorpicker",{"name":"ifequal","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":17,"column":16}}})) != null ? stack1 : "")
    + "  </div>\n";
},"11":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"response_options") : depth0),{"name":"each","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":6},"end":{"line":16,"column":15}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../js/helpers/ifans.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"answer") : depths[1]),(depths[1] != null ? lookupProperty(depths[1],"input_id") : depths[1]),(depth0 != null ? lookupProperty(depth0,"code") : depth0),{"name":"ifans","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":8},"end":{"line":15,"column":18}}})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          "
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0)) != null ? stack1 : "")
    + "\n";
},"15":function(container,depth0,helpers,partials,data) {
    return " colorpicker-response-list-columns";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dk-center\">\n  <input type=\"checkbox\" class=\"dk-check\" id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"input_id") : depth0), depth0))
    + "-dk\" />\n  <span class=\"dk-label\"\n    ><label for=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"input_id") : depth0), depth0))
    + "-dk\">"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"dk_text") : depth0), depth0)) != null ? stack1 : "")
    + "</label></span\n  >\n</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<fieldset class=\"question question-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"type") : depth0), depth0))
    + "\">\n  <legend class=\"question-text\" id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"element_id") : depth0), depth0))
    + "-label\">\n    "
    + ((stack1 = __default(require("../js/helpers/ifequal.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"language",{"name":"ifequal","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(8, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":6,"column":16}}})) != null ? stack1 : "")
    + "  </legend>\n"
    + ((stack1 = __default(require("../js/helpers/ifanswerexists.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"input_id") : depth0),{"name":"ifanswerexists","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":2},"end":{"line":19,"column":21}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(require("./error.hbs"),depth0,{"name":"error","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n  <div class=\"question-response-list"
    + ((stack1 = __default(require("../js/helpers/ifcond.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"columns") : depth0),">",1,{"name":"ifcond","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":36},"end":{"line":23,"column":106}}})) != null ? stack1 : "")
    + "\"></div>\n</fieldset>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"dk") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":0},"end":{"line":32,"column":7}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true,"useDepths":true});